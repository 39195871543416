import { registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import localeBr from '@angular/common/locales/pt';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ServiceWorkerModule,
  SwRegistrationOptions
} from '@angular/service-worker';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonsModule } from './core/commons';
import { GlobalErrorHandler } from './core/errors/GlobalErrorHandler';
import { httpInterceptorProviders } from './core/interceptors';
import { UpdateService } from './core/services/update.service';
import { ShellModule } from './shell/shell.module';
import { environment } from '../environments/environment';

registerLocaleData(localeBr, 'pt-BR');

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    HammerModule,
    ReactiveFormsModule,

    BrowserAnimationsModule,
    ShellModule,
    CommonsModule,
    NgbAlertModule,
    AppRoutingModule,
    NgbModule,
    NgxCaptchaModule,
    AngularSvgIconModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js'),
    NgxGoogleAnalyticsModule.forRoot('UA-157147625-6')
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:30000'
      })
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    httpInterceptorProviders,
    UpdateService,
    provideHttpClient(withInterceptorsFromDi()),
    provideHotToastConfig({
      reverseOrder: true
    })
  ]
})
export class AppModule {}
