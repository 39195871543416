import { Injectable } from '@angular/core';
import { EMPTY, catchError } from 'rxjs';

import { ApiLiv } from '../../services/api/api-liv.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(private apiLiv: ApiLiv) {}

  logError(message: string, stack?: string): void {
    console.error(message, stack);
  }

  sendErrorToServer(message: string, stack?: string): void {
    this.apiLiv
      .post<void>(`/log/message`, {
        message,
        stackTrace: stack
      })
      .pipe(catchError(() => EMPTY))
      .subscribe();
  }
}
