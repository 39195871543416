import { Injectable, OnDestroy } from '@angular/core';
import { take } from 'rxjs';
import { LIVAnalyticsModel } from 'src/app/core/models/liv-analytics.model';
import { AuthProps, AuthStore } from 'src/app/shared/store/auth.store';

import { ApiLiv } from './api/api-liv.service';

@Injectable({
  providedIn: 'root'
})
export class LIVAnalyticsService implements OnDestroy {
  private clickListener: () => void;

  constructor(
    private apiLiv: ApiLiv,
    private authStore: AuthStore
  ) {
    this.setupEventListener();
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.clickListener);
  }

  private setupEventListener(): void {
    this.clickListener = this.saveLIVAnalyticsEvent.bind(this);
    document.addEventListener('click', this.clickListener);
  }

  private makeTrackingPayload(
    target: HTMLElement,
    auth: AuthProps
  ): LIVAnalyticsModel {
    const trackingId = target.getAttribute('data-tracking-id');
    const objectData = target.getAttribute('data-tracking-object');

    const formatUserData = (data: AuthProps) => {
      if (!data) {
        return null;
      }

      return {
        id: data.id,
        nome: data.nome,
        email: data.email,
        perfisAcesso: data.perfisAcesso,
        espacoSer: data.espacoSer
      };
    };

    const model: LIVAnalyticsModel = {
      trackingId,
      url: window.location.href,
      urlTo: target.baseURI,
      type: 'click',
      cssClasses: target.classList.value,
      htmlId: target.id,
      htmlTag: target.tagName,
      htmlText: target.textContent?.trim() || null,
      linkHref: target.getAttribute('href'),
      imgSrc: target.getAttribute('src'),
      imgAlt: target.getAttribute('alt'),
      userData: formatUserData(auth),
      objectData
    };

    return model;
  }

  public saveLIVAnalyticsEvent(ev: Event): void {
    const target = ev.target as HTMLElement;

    if (!target.hasAttribute('data-tracking-id')) {
      return;
    }

    const userData = { ...this.authStore.authSnapshot };

    if (!userData?.token) {
      return;
    }

    const payload = this.makeTrackingPayload(target, userData);

    this.apiLiv
      .post<void>('/Kibana/LIVAnalytics/SaveEvent', payload)
      .pipe(take(1))
      .subscribe();
  }
}
