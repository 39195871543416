import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors';

import { LivErrorResponse } from '../models/liv-response-protocol.model';
import { ToastService } from '../services/toast.service';
import { ErrorService } from './services/error.service';
import { LoggerService } from './services/logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone,
    private injector: Injector
  ) {}

  handleError(
    error:
      | Error
      | LivErrorResponse
      | HttpErrorResponse
      | FailedToNegotiateWithServerError
  ): void {
    const toastService = this.injector.get(ToastService);
    const errorService = this.injector.get(ErrorService);
    const loggerService = this.injector.get(LoggerService);

    let message: string;
    let stackTrace: string;

    if (error instanceof FailedToNegotiateWithServerError) {
      return;
    }

    let isHttpErrorResponse = false;

    if (error instanceof HttpErrorResponse) {
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
      isHttpErrorResponse = true;
    } else if (this._isLivErrorResponse(error)) {
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
      isHttpErrorResponse = true;
    } else {
      message = errorService.getClientMessage(error);
      stackTrace = errorService.getClientStack(error);
    }

    this.zone.run(() => {
      loggerService.logError(message, stackTrace);

      if (isHttpErrorResponse) {
        toastService.error(message);
        loggerService.sendErrorToServer(message, stackTrace);
      }
    });
  }

  private _isLivErrorResponse(
    error: Error | LivErrorResponse
  ): error is LivErrorResponse {
    const livErrorResponse = error as LivErrorResponse;
    if (livErrorResponse.status && livErrorResponse.error.message in error) {
      return true;
    }
    return false;
  }
}
