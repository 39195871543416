import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthStore } from 'src/app/shared/store/auth.store';

export const urisNoNeedsAuthToken = [
  '/assets/',
  'api.inteligenciadevida',
  'api.editora.inteligenciadevida',
  '.gestaoconteudos.portfolio',
  'localhost:4000',
  'localhost:44364'
];

export function requestNeedsAuthToken(request: HttpRequest<unknown>): boolean {
  return new RegExp(urisNoNeedsAuthToken.join('|')).test(request.url);
}

export function requestApiLivOrPortfolio(
  request: HttpRequest<unknown>
): boolean {
  return new RegExp(
    ['.gestaoconteudos.portfolio', 'localhost:4000'].join('|')
  ).test(request.url);
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authStore: AuthStore) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const auth = this.authStore.authSnapshot;

    if (
      request.url.includes('openid-configuration') ||
      request.url.includes('googleapi')
    ) {
      return next.handle(request);
    }

    if (request.url.includes('/api/auth/local')) {
      return next.handle(request);
    }

    if (
      (auth?.token || auth?.tokenPortfolio) &&
      requestNeedsAuthToken(request)
    ) {
      const token = requestApiLivOrPortfolio(request)
        ? auth.tokenPortfolio
        : auth.token;

      if (!token) {
        return next.handle(request);
      }

      return next.handle(
        request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        })
      );
    }

    return next.handle(request);
  }
}
