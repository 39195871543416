import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContentTypeInterceptor } from './content-type.interceptor';
import { ResponseProtocolInterceptor } from './response-protocol.interceptor';
import { TokenInterceptor } from './token.interceptor';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './timeout.interceptor';

const TIMEOUT_VALUE_IN_MINUTES = 5;

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ResponseProtocolInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TimeoutInterceptor,
    multi: true
  },
  { provide: DEFAULT_TIMEOUT, useValue: TIMEOUT_VALUE_IN_MINUTES * 60 * 1000 },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true }
];
