import { Component, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';
import { ApiLiv } from '../../services/api/api-liv.service';
import { ToastService } from '../../services/toast.service';
import { ExternalLinkModel } from './models/external-link.model';

@Component({
  selector: 'liv-external-urls',
  template: '<div></div>'
})
export class ExternalURLsComponent {}

@Injectable({
  providedIn: 'root'
})
export class RedirectSiteGuard  {
  constructor(
    private router: Router,
    private apiLiv: ApiLiv,
    private toastService: ToastService
  ) {}

  canActivate(
    _router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const oldUrl = state.url;
    const localRouter = this.router;

    if (oldUrl.includes('/material?name=')) {
      this.getOldRoute(oldUrl).subscribe({
        next(x) {
          const urlData = x;

          if (urlData !== null || urlData !== undefined) {
            switch (urlData.type) {
              case 0:
                localRouter.navigateByUrl(
                  localRouter.createUrlTree(urlData.path, urlData.params)
                );

                break;
              case 1:
                window.open(urlData.path.join(''), '_self');

                break;
              default:
                localRouter.createUrlTree(urlData.path, urlData.params);

                break;
            }
          }
        }
      });
    }

    return true;
  }

  getOldRoute(link: string): Observable<ExternalLinkModel> {
    return this.apiLiv
      .post<ExternalLinkModel>('/Material/ExternalLink', {
        oldLink: link
      })
      .pipe(
        catchError(() => {
          this.toastService.error(
            'Houve um erro ao redirecionar para o material'
          );

          return EMPTY;
        })
      );
  }
}
