import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthStore } from 'src/app/shared/store/auth.store';

@Injectable({ providedIn: 'root' })
export class AuthPreloadStrategy implements PreloadingStrategy {
  constructor(private authStore: AuthStore) {}

  preload(_: Route, fn: () => Observable<any>): Observable<any> {
    return this.authStore.isAuthenticated$.pipe(
      switchMap((isLoggedIn) => (isLoggedIn ? fn() : of(null)))
    );
  }
}
