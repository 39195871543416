import { Component, HostListener, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { filter } from 'rxjs/operators';
import { register } from 'swiper/element/bundle';

import { ApiLivRequestsService } from './core/services/api/requests/api-liv-requests.service';
import { LIVAnalyticsService } from './core/services/liv-analytics.service';
import { UpdateService } from './core/services/update.service';
import { AuthStore } from './shared/store/auth.store';
import { ModalService } from './ui/components/modal/modal.service';

register();

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'portal-liv';

  constructor(
    private authStore: AuthStore,
    private gaService: GoogleAnalyticsService,
    private livAnalyticsService: LIVAnalyticsService,
    private updateService: UpdateService,
    private apiLiv: ApiLivRequestsService,
    private modal: ModalService
  ) {
    this.updateService.checkForUpdates();
  }

  ngOnInit(): void {
    this.registerAnalytics();
    /* this.listenerAcceptTerms(); TO DO*/
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    this.livAnalyticsService.saveLIVAnalyticsEvent(event);
  }

  registerAnalytics(): void {
    this.authStore.isAuthenticated$
      .pipe(filter((auth) => auth))
      .subscribe(() => {
        this.gaService.set({
          custom_map: { dimension1: 'Escola', dimension2: 'Pessoa' }
        });
        this.gaService.gtag('enter_name', 'user_register_form', 'Name');
      });
  }

  /*   private listenerAcceptTerms(): void {
    this.authStore.isAuthenticated$
      .pipe(
        filter(Boolean),
        filter(() => !this.authStore.hasAcceptedTermsSnapshot),
        switchMap(() =>
          this.apiLiv.checkUserHasAcceptedTerms().pipe(
            tap((hasAccepted) => {
              if (hasAccepted) {
                this.authStore.setHasAcceptedTerms(hasAccepted);
              }
            })
          )
        ),
        filter((hasAccepted) => !hasAccepted)
      )
      .subscribe(() => {
        this.modal.open(AcceptTermsModalComponent, {
          options: {
            isCloseButtonVisible: false,
            disableBackdropClose: true
          }
        });
      });
  } TO DO */
}
