import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const whiteList = ['/assets/', '/importData', '/upload'];

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const whiteListed = new RegExp(whiteList.join('|')).test(req.url);

    if (whiteListed) {
      return next.handle(req);
    }

    if (req.method === 'POST' && req.body instanceof FormData) {
      const request = req.clone({
        headers: req.headers.delete('Content-Type')
      });

      return next.handle(request);
    }

    const request = req.clone({
      setHeaders: {
        'Content-Type': 'application/json'
      }
    });

    return next.handle(request);
  }
}
