import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(public updates: SwUpdate) {
    if (updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(
        () =>
          void updates
            .checkForUpdate()
            // eslint-disable-next-line no-console
            .then(() => console.info('checking for updates'))
      );
    }
  }

  public checkForUpdates(): void {
    this.updates.versionUpdates.subscribe((event) => {
      if (event.type === 'VERSION_READY') {
        this.promptUser();
      }
    });
  }

  private promptUser(): void {
    // eslint-disable-next-line no-console
    console.info('updating to new version');
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
